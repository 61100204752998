export enum AccountState {
  OnboardingCompleted = 'ONBOARDING_COMPLETED',
  OnboardingDataCaptured = 'ONBOARDING_DATA_CAPTURED',
  Investor = 'INVESTOR',
  TncAccepted = 'TERMS_AND_CONDITIONS_ACCEPTED',
  TrialInvestor = 'TRIAL_INVESTOR',
  TrialInvestorRevoked = 'TRIAL_INVESTOR_REVOKED',
  RejectedInvestor = 'REJECTED_INVESTOR',
  OnHold = 'ONHOLD_INVESTOR'
}

export enum AccountStateModule {
  Mortgage = 'MORTGAGE',
}
