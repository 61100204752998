import { AccountInfo } from '@azure/msal-browser';
import { createAction, props } from '@ngrx/store';

import { NavSegments, uuid } from '@aw/shared/models';

import { BusinessUnit } from '@aw/prypco/enums';
import {
  AccountState,
  AccountStates,
  ExternalIdentifier,
} from '@aw/prypco/services/user';

import { MemberInfoCustomer } from './auth.effects';
import { LeadIdRecord } from './models/entities/lead-id-record.model';
import { LeadRecord } from './models/entities/lead-record.model';

export const init = createAction('[Auth] Init');

export const setActiveAccount = createAction(
  '[Auth Effect] Set Active Account',
  props<{
    account: AccountInfo | undefined;
    leadIdRecord: LeadIdRecord;
    leadRecord: LeadRecord;
    accountStates?: Record<BusinessUnit, AccountStates>;
    externalIdentifiers: Array<ExternalIdentifier>;
    customer?: MemberInfoCustomer;
  }>(),
);

export const signUp = createAction(
  '[Auth] Sign Up',
  props<{ redirectUri: Array<string> }>(),
);

export const signUpSalesAgent = createAction(
  '[Auth] Sign Up Sales Agent',
  props<{ redirectUri: Array<string> }>(),
);

export const logOut = createAction(
  '[Auth] Log Out',
  props<{ businessUnit?: BusinessUnit; redirectUri?: NavSegments }>(),
);

export const signIn = createAction(
  '[Auth] Sign In',
  props<{ redirectUri: NavSegments }>(),
);

export const setLeadId = createAction(
  '[Auth] Set Lead ID',
  props<{ leadId: uuid; businessUnit: BusinessUnit }>(),
);

export const setLead = createAction(
  '[Auth] Set Lead',
  props<{ lead: LeadRecord; businessUnit: BusinessUnit }>(),
);

export const resetPassword = createAction('[Auth] Reset Password');

export const refreshAccountStates = createAction(
  '[Auth] Refresh account states',
);

export const setAccountStates = createAction(
  '[Auth] Set account states',
  props<{
    accountStates: Record<BusinessUnit, Partial<Record<AccountState, boolean>>>;
  }>(),
);

export const setAccountState = createAction(
  '[Auth] Set account state',
  props<{
    businessUnit: BusinessUnit;
    accountState: AccountState;
    value: boolean;
  }>(),
);
