import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEmpty, keys } from 'lodash-es';
import {
  Observable,
  catchError,
  concatMap,
  exhaustMap,
  first,
  firstValueFrom,
  from,
  map,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';

import { NavSegments, uuid } from '@aw/shared/models';
import { LocalStorageService } from '@aw/shared/services/local-storage';
import { composeHref } from '@aw/shared/utilities';

import { redirectBusinessUnitStorageKey } from '@aw/prypco/constants';
import { BusinessUnit, Module } from '@aw/prypco/enums';
import {
  resetPasswordAuthority,
  signInAuthority,
  signUpAuthority,
  signUpSalesAgentAuthority,
  tokenScope,
} from '@aw/prypco/environment';
import { AmplitudeService } from '@aw/prypco/services/script-manager';
import {
  AccountStates,
  ExternalIdentifier,
  UserInfoResponse,
  UserService,
} from '@aw/prypco/services/user';
import { SplashScreenFacade } from '@aw/prypco/state/splash-screen';

import * as AuthActions from './auth.actions';
import { AuthFacade } from './auth.facade';
import { LeadIdRecord } from './models/entities/lead-id-record.model';
import { LeadRecord } from './models/entities/lead-record.model';

export interface MemberInfoCustomer {
  id?: uuid;
  firstName?: string;
  lastName?: string;
}

interface MemberInfo {
  leadIdRecord: LeadIdRecord;
  leadRecord: LeadRecord;
  accountStates: Record<BusinessUnit, AccountStates>;
  externalIdentifiers: Array<ExternalIdentifier>;
  customer?: MemberInfoCustomer;
}

@Injectable()
export class AuthEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.init),
      tap(() => this.splashScreenFacade.setLoading('auth', true)),
      concatMap(() => {
        const account = this.getActiveAccount();

        if (account) {
          return this.loadMemberInfo().pipe(
            map(
              ({
                leadIdRecord,
                leadRecord,
                accountStates,
                customer,
                externalIdentifiers,
              }) => ({
                account,
                leadIdRecord,
                leadRecord,
                accountStates,
                externalIdentifiers,
                customer,
              }),
            ),
            catchError(() => {
              this.store.dispatch(
                AuthActions.logOut({ businessUnit: BusinessUnit.Corporate }),
              );

              return of({
                account,
                leadId: undefined,
                leadIdRecord: {},
                leadRecord: {},
                customer: undefined,
                accountStates: undefined,
                externalIdentifiers: [],
              });
            }),
          );
        }

        return of({
          account,
          leadId: undefined,
          customer: undefined,
          leadIdRecord: {},
          leadRecord: {},
          accountStates: undefined,
          externalIdentifiers: [],
        });
      }),
      map(
        ({
          account,
          leadIdRecord,
          leadRecord,
          customer,
          accountStates,
          externalIdentifiers,
        }) =>
          AuthActions.setActiveAccount({
            account,
            leadIdRecord,
            leadRecord,
            accountStates,
            externalIdentifiers,
            customer,
          }),
      ),
      tap(() => this.splashScreenFacade.setLoading('auth', false)),
    ),
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logOut),
        tap(({ businessUnit, redirectUri }) => {
          this.localStorageService.set(
            redirectBusinessUnitStorageKey,
            businessUnit,
          );

          const composedUrl = this.composeRedirectUrl(
            redirectUri || [Module.AuthRedirect],
          );

          this.msalService.logout({ postLogoutRedirectUri: composedUrl });
        }),
      ),
    {
      dispatch: false,
    },
  );

  signUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signUp),
        exhaustMap(({ redirectUri }) =>
          from(this.msalService.instance.handleRedirectPromise()).pipe(
            first(),
            map(() => redirectUri),
          ),
        ),
        withLatestFrom(this.authFacade.authenticated$),
        tap(async ([redirectUri, authenticated]) => {
          if (authenticated) {
            void this.router.navigate(redirectUri);
          } else {
            const composedUrl = `${location.protocol}//${
              location.host
            }/${redirectUri.join('/')}`;

            const params = this.addTrackingQueryParams({
              authority: signUpAuthority,
              redirectUri: composedUrl,
              scopes: [tokenScope],
              redirectStartPage: composedUrl,
            });

            const loginRedirect$ = this.msalService.loginRedirect(params);

            await firstValueFrom(loginRedirect$);
          }
        }),
      ),
    { dispatch: false },
  );

  signUpSalesAgent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signUpSalesAgent),
        exhaustMap(({ redirectUri }) =>
          from(this.msalService.instance.handleRedirectPromise()).pipe(
            first(),
            map(() => redirectUri),
          ),
        ),
        tap(async (redirectUri) => {
          const composedUrl = `${location.protocol}//${
            location.host
          }/${redirectUri.join('/')}`;
          const params: RedirectRequest = this.addTrackingQueryParams({
            authority: signUpSalesAgentAuthority,
            redirectUri: composedUrl,
            scopes: [tokenScope],
            redirectStartPage: composedUrl,
          });
          await firstValueFrom(this.msalService.loginRedirect(params));
        }),
      ),
    { dispatch: false },
  );

  signIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signIn),
        exhaustMap(({ redirectUri }) =>
          from(this.msalService.instance.handleRedirectPromise()).pipe(
            first(),
            map(() => redirectUri),
          ),
        ),
        tap(async (redirectUri) => {
          const composedUrl = this.composeRedirectUrl(redirectUri);

          const params: RedirectRequest = this.addTrackingQueryParams({
            authority: signInAuthority,
            redirectUri: composedUrl,
            scopes: [tokenScope],
            redirectStartPage: composedUrl,
          });

          await firstValueFrom(this.msalService.loginRedirect(params));
        }),
      ),
    { dispatch: false },
  );

  resetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.resetPassword),
        exhaustMap(() =>
          from(this.msalService.instance.handleRedirectPromise()).pipe(first()),
        ),
        tap(async () => {
          const composedUrl = composeHref();

          const params = this.addTrackingQueryParams({
            authority: resetPasswordAuthority,
            redirectUri: composedUrl,
            scopes: [tokenScope],
            redirectStartPage: composedUrl,
          });

          await firstValueFrom(this.msalService.loginRedirect(params));
        }),
      ),
    { dispatch: false },
  );

  refreshAccountStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshAccountStates),
      exhaustMap(() => this.loadMemberInfo()),
      map((userInfo) =>
        AuthActions.setAccountStates({ accountStates: userInfo.accountStates }),
      ),
    ),
  );

  constructor(
    private readonly amplitudeService: AmplitudeService,
    private readonly store: Store,
    private readonly authFacade: AuthFacade,
    private readonly actions$: Actions,
    private readonly msalService: MsalService,
    private readonly userService: UserService,
    private readonly splashScreenFacade: SplashScreenFacade,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
  ) {}

  private getActiveAccount(): AccountInfo {
    return this.msalService.instance.getActiveAccount() as AccountInfo;
  }

  private loadMemberInfo(): Observable<MemberInfo> {
    return this.userService.getInfo().pipe(
      map((userInfo) => {
        const customer: MemberInfoCustomer = {
          id: userInfo.customerId,
          firstName: userInfo.customerFirstName,
          lastName: userInfo.customerLastName,
        };

        return {
          leadIdRecord: this.composeLeadIdRecord(userInfo),
          leadRecord: this.composeLeadRecord(userInfo),
          accountStates: this.composeAccountStatesRecord(userInfo),
          externalIdentifiers: userInfo.externalIdentifiers,
          customer: isEmpty(customer) ? undefined : customer,
        };
      }),
    );
  }

  private readonly composeLeadRecord = (
    userInfoRes: UserInfoResponse,
  ): LeadRecord => {
    const businessUnits = keys(
      userInfoRes.businessUnits,
    ) as Array<BusinessUnit>;

    return businessUnits.reduce((acc: LeadRecord, businessUnit) => {
      acc[businessUnit] = userInfoRes.businessUnits[businessUnit];
      
      return acc;
    }, {} as LeadRecord);
  };

  private readonly composeLeadIdRecord = (
    userInfoRes: UserInfoResponse,
  ): LeadIdRecord => {
    const businessUnits = keys(
      userInfoRes.businessUnits,
    ) as Array<BusinessUnit>;

    return businessUnits.reduce((acc: LeadIdRecord, businessUnit) => {
      acc[businessUnit] = userInfoRes.businessUnits[businessUnit].leadId;

      return acc;
    }, {} as LeadIdRecord);
  };

  private readonly composeAccountStatesRecord = (
    userInfoRes: UserInfoResponse,
  ): Record<BusinessUnit, AccountStates> => {
    const businessUnits = keys(
      userInfoRes.businessUnits,
    ) as Array<BusinessUnit>;

    return businessUnits.reduce(
      (acc: Record<BusinessUnit, AccountStates>, businessUnit) => {
        acc[businessUnit] = userInfoRes.businessUnits[businessUnit]
          .states as AccountStates;

        return acc;
      },
      {} as Record<BusinessUnit, AccountStates>,
    );
  };

  private addTrackingQueryParams(
    redirectRequest: RedirectRequest,
  ): RedirectRequest {
    if (this.amplitudeService.shouldTrack && this.amplitudeService.deviceId) {
      return {
        ...redirectRequest,
        extraQueryParameters: {
          analytics: 'true',
          deviceId: this.amplitudeService.deviceId,
        },
      };
    }
    return redirectRequest;
  }

  private readonly composeRedirectUrl = (segments: NavSegments): string =>
    `${window.location.protocol}//${
      window.location.host
    }/${segments.join('/')}`;
}
